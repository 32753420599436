.nav-pills {
	border-radius: 5px;
	z-index: 1;
}

.nav-pills .nav-link.active {
	background-color: $primary-color;
	img {
		top: 80%;
	}
}

.nav-pills .nav-link {
	background-color: $lotion-color;
	color: $dark-gray-text-color;

	.nav-pills-img {
		position: relative;
		right: calc(50% - 5px);
		z-index: -10;
	}

	span {
		margin-left: 11px;
	}
}
