@import 'styles-color.scss';

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $primary-color;
	border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: $primary-color;
	border-radius: 2px;
}
