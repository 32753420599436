@import './styles-color.scss';

.company-table-container {
	background-color: $cultured-color;
	padding: 20px;
	border-radius: 5px;

	.table-header-actions {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		margin-bottom: 1rem;

		.table-title {
			display: flex;
			width: 100%;
			align-items: center;

			.title {
				font-style: normal;
				font-weight: 700;
				font-size: 24px;
				line-height: 29px;
				margin: 0 8px;
			}

			.collection-size {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 25px;
				height: 25px;
				background-color: $primary-color;
				border-radius: 15px;
				color: white;
				font-weight: 700;
				font-size: 11px;
				line-height: 13px;
			}
		}

		.filter-actions {
			display: flex;
			align-items: center;

			> .filter-element {
				margin-left: 20px;
			}
		}
	}

	.table {
		margin-bottom: 0;

		> :not(:first-child) {
			border-top-color: $light-text-color;
		}

		th {
			padding: 8px 0;

			.table-header-container {
				width: 100%;
				text-align: center;
				color: $primary-color;
				font-style: normal;
				font-weight: 700;
				font-size: 12px;
				line-height: 20px;
				text-transform: uppercase;
				margin: 5px 0;
				border-right: 1px solid #00a888;
			}

			&:last-child {
				.table-header-container {
					border-right: none;
				}
			}
		}

		th[sortable] {
			cursor: pointer;

			&.desc:before {
				content: '';
				position: relative;
				background: url('/assets/icons/sort-arrow-down.svg') no-repeat;
				width: 15px;
				height: 20px;
				margin: 5px;
				float: left;
				margin-right: -20px;
			}

			&.asc:before {
				content: '';
				position: relative;
				background: url('/assets/icons/sort-arrow-down.svg') no-repeat;
				width: 15px;
				height: 20px;
				float: left;
				margin: 5px;
				transform: rotate(180deg);
				margin-right: -20px;
			}

			&:first-child {
				&.desc:before {
					margin-right: 5px;
				}

				&.asc:before {
					margin-right: 5px;
				}

				&:before {
					content: '';
					display: block;
					width: 15px;
					height: 20px;
					margin: 5px;
					float: left;
				}
			}
		}

		th:first-child {
			.table-header-container {
				text-align: start;
			}
		}

		td {
			text-align: center;
		}

		td:first-child {
			text-align: start;
		}

		tbody > tr > td {
			padding-top: 15px;
			padding-bottom: 15px;
			vertical-align: middle;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;

			.table-body-container {
				display: flex;
				align-items: center;

				.table-icon {
					margin-right: 8px;
				}
			}

			img {
				margin-right: 10px;
			}
		}

		.table-actions {
			> img {
				margin: 5px;
				cursor: pointer;
			}

			.accept-button {
				border: 2px solid $primary-color;
				border-radius: 5px;
				background-color: transparent;
				margin: 0 5px;
				color: $primary-color;
				font-weight: 700;
				font-size: 11px;
				line-height: 13px;
				padding: 1px 10px;

				img {
					margin-right: 8px;
				}
			}

			.decline-button {
				border: none;
				border-radius: 5px;
				background-color: $gray-color;
				margin: 0 5px;
				color: $special-black-color;
				font-weight: 700;
				font-size: 11px;
				line-height: 13px;
				padding: 1px 10px;

				img {
					margin-right: 8px;
				}
			}
		}
	}

	.table-pagination {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 30px;

		.pagination {
			.page-link {
				border: none;
				background-color: transparent;
				font-weight: 700;
				font-size: 18px;
				line-height: 22px;
				color: black;

				&:focus {
					box-shadow: none;
				}
			}

			.page-item {
				&:first-child .page-link {
					color: $primary-color;
				}

				&:last-child .page-link {
					color: $primary-color;
				}

				&.active .page-link {
					color: $primary-color;
					text-decoration: underline;
				}
			}
		}
	}
}
