$primary-color: #00a888;
$secondary-color: #ffffff;
$light-color: #3dbda4;
$gray-color: #e0e0e0;
$black-color: #000000;
$anti-flash-white-color: #f1f1f1;
$special-black-color: #131313;
$french-blue-color: #0072ba;
$american-yellow-color: #edbf03;
$lotion-color: #fafafa;
$sweet-pink-color: #f39494;
$cultured-color: #f8f8f8;

$primary-color-hover: #005848;

$primary-text-color: #ffffff;
$secondary-text-color: #00a888;
$light-text-color: #3dbda4;
$gray-text-color: #e0e0e0;
$dark-gray-text-color: #bababa;
$black-text-color: #000000;
