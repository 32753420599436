@import './styles-color.scss';

.lmodal-class {
	max-width: 600px;
	width: 100%;
}

.gpe-modal-content {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 20px;

	.gpe-modal-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		.title {
			font-weight: 700;
			font-size: 24px;
			line-height: 29px;
			color: $special-black-color;
			flex-grow: 1;
		}

		.modal-icon {
			margin: 5px;
			cursor: pointer;
		}
	}

	.gpe-modal-form {
		margin: 10px 0;

		.gpe-modal-textarea {
			background-color: $cultured-color;
			resize: none;
		}

		.gpe-modal-checkbox {
			margin-right: 8px;
		}
	}

	.gpe-modal-actions {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 10px 0;
	}
}

.first-confirm-btn {
	width: 100%;
	background-color: $primary-color;
	color: $secondary-color;
	margin-top: 8px;
}
