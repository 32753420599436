@import 'styles-color';

h2 {
	color: $secondary-text-color;
	font-weight: bold;
}

// Primary Button
.btn-primary.disabled,
.btn-primary:disabled {
	background-color: $gray-color;
	border-color: $gray-color;
}

.btn-primary {
	background-color: $light-color;
	border-color: $light-color;

	&:hover {
		background-color: $primary-color-hover;
		border-color: $primary-color-hover;
	}

	&:active {
		background-color: $light-color;
		border-color: $light-color;

		&:focus {
			box-shadow: 0 0 0 0.25rem rgb($primary-color, 0.5);
		}
	}

	&:focus {
		box-shadow: 0 0 0 0.25rem rgb($primary-color, 0.5);
		background-color: $primary-color-hover;
		border-color: $primary-color-hover;
	}
}

.btn-outline-primary {
	border-color: $primary-color;
	color: $primary-color;

	&:hover {
		background-color: transparent;
		border-color: $primary-color;
		color: $primary-color;
	}

	&:active {
		background-color: transparent;
		border-color: $primary-color;
		color: $primary-color;

		&:focus {
			box-shadow: 0 0 0 0.25rem rgb($primary-color, 0.5);
		}
	}

	&:focus {
		box-shadow: 0 0 0 0.25rem rgb($primary-color, 0.5);
	}
}

// Secondary Button

.btn-secondary,
.btn-secondary:focus {
	background-color: $secondary-color;
	border-color: $gray-color;
	color: $secondary-text-color;
}

.btn-secondary:hover {
	background-color: $light-color;
	border-color: $gray-color;
	color: $primary-text-color;
}

.btn-secondary:active {
	background-color: $light-color !important;
	border-color: $gray-color !important;
	color: $primary-text-color !important;
}

.text-justify {
	text-align: justify;
}

// Checkbox
.form-check-input {
	cursor: pointer;
	&:checked {
		background-color: $primary-color;
		border-color: $primary-color;
	}
	&:focus {
		box-shadow: 0 0 0 0.25rem rgb($primary-color, 0.4);
		border-color: transparent;
	}
}

// Forms
.form-control {
	&:focus {
		border-color: transparent;
		box-shadow: 0 0 0 0.25rem rgb($primary-color, 0.4);
	}

	&.ng-invalid {
		&.ng-dirty {
			border-color: transparent;
			box-shadow: 0 0 0 0.25rem rgb(red, 0.4);
		}
	}
}

.dropdown-item:active {
	background-color: $primary-color;
}
