@import '~swiper/swiper-bundle.css';
@import './assets/styles/styles-color';
@import './assets/styles/styles-bootstrap-redefine';
@import './assets/styles/scrollbar';
@import './assets/styles/gpe-modal';
@import './assets/styles/bootsrap-customization';
@import './assets/styles/styles-bootstrap-table-redefine.scss';

html,
body {
	width: 100%;
	min-height: 100%;
	font-family: 'Sen', serif;
}

.tagify {
	border: 0;
}

label {
	padding-left: 0.8rem;
	margin-bottom: 0.1rem;
}

button {
	height: 39px;
}

.cursor-pointer {
	cursor: pointer;
}

.error-message {
	color: crimson;
}

.button-container {
	width: 25vw;
	min-width: 300px;
	display: flex;

	.cancel-button {
		flex-grow: 2;
		background-color: $secondary-color;
	}

	.submit-button {
		flex-grow: 3;
		margin-left: 5px;
	}
}

.redirect {
	font-weight: bold;
	cursor: pointer;
}

.auth-filler-text {
	opacity: 0.4;
	margin-top: 20px;
	margin-bottom: 20px;
	text-align: center;
	font-size: smaller;
}

.invalid-feedback {
	display: block;
}

.avatar-image {
	border-radius: 100%;
	width: 50px;
	height: 50px;
	display: block;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.avatar-image-sm {
	border-radius: 100%;
	width: 35px;
	height: 35px;
	display: block;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.loading-animation-container {
	display: inline-block;
	width: 80px;
	height: 80px;
}
.loading-animation-container-sm {
	display: inline-block;
	width: 35px;
	height: 35px;
}
.loading-animation-container-xs {
	display: inline-block;
	width: 15px;
	height: 15px;
}

.xlmodal-backdrop-style {
	background: rgb(0, 0, 0);
	opacity: 0.8 !important;
}
.xlmodal-class {
	max-width: 100%;
	width: 1000px;
}
.xlmodal-class .modal-content {
	border: 0;
}
.lgmodal-class {
	max-width: 100%;
	width: 550px;
}

.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.btn-block {
	width: 100%;
	display: block !important;
}
.dropdown-toggle::after {
	display: none;
}

.modal-close-button {
	float: right;
	right: 5px;
	top: -30px;
	position: absolute;
	min-width: 70px;
	height: 30px;
	background: #00a888;
	border-radius: 5px 5px 0px 0px;
	font-weight: 400;
	font-size: 13px;
	line-height: 30px;
	color: #ffffff !important;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
}
.pac-container {
	z-index: 1800 !important;
}

.empty-list {
	margin-top: 50px;
	text-align: center;

	img {
		max-width: 60%;
	}

	.empty-list-title {
		margin-top: 20px;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 28px;
		text-align: center;
		color: #131313;
	}
}

.no-display-1600 {
	@media screen and (max-width: 1600px) {
		display: none !important;
	}
}

.no-display-1360 {
	@media screen and (max-width: 1360px) {
		display: none !important;
	}
}

.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-d {
	overflow: hidden !important;
	padding: 0 !important;
}
.gm-style .gm-style-iw-c button {
	display: none !important;
}
.gm-style .gm-style-iw-t::after {
	z-index: -1;
}
